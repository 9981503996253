
export let HTTP_METHOD_GET = "GET";
export let HTTP_METHOD_POST = "POST";
export let HTTP_METHOD_PUT = "PUT";
export let HTTP_METHOD_DELETE = "DELETE";

export let HTTP_HEADERS_JSON = { "Accept": "application/json", "Content-Type": "application/json" };

export let API_ENDPOINT_ADS = "/api/vehicles-catalog";
export let API_ENDPOINT_SERVICE_ADVERTS = "/api/services/adverts";
export let API_ENDPOINT_ADVERTISERLOCATIONS = "/api/advertiserlocations";
export let API_ENDPOINT_ADVERTISERS = "/api/advertiser";
export let API_ENDPOINT_BALANCE = "/api/balance";
export let API_ENDPOINT_REFDATA = "/api/refdata";
export let API_ENDPOINT_AUTHORIZATIONAPPS = "/api/authorizationApps";
export let API_ENDPOINT_USERS = "/api/advertiserusers";
export let API_ENDPOINT_FILES = "/api/files";
export let API_ENDPOINT_LEADS = "/api/leads";
export let API_ENDPOINT_ALERTS = "/api/alerts";
export let API_ENDPOINT_HIGHLIGHTS = "/api/highlights";
export let API_ENDPOINT_PROCUREMENT = "/api/procurement";
export let API_ENDPOINT_STATS = "/api/stats";
export let API_ENDPOINT_IMAGES = "/images";
export let API_ENDPOINT_SERVICES = "/api/services";
export let API_ENDPOINT_SESSION_FILTERS = "/api/sessions";

export let SESSION_FILTER_KEY_ADS_SEARCH = "ADS_SEARCH_FILTER";
export let API_ENDPOINT_CARMATCHUSED = "/api/carmatchused";

export let SEARCH_PAGE_DEFAULT_SIZE = 12;
export let SEARCH_PAGE_DEFAULT_NUMBER = 1;
export let SEARCH_PAGE_DEFAULT_ORDER_BY = "";

export let LIST_ORDER_DIRECTION_ASC = "ASC";
export let LIST_ORDER_DIRECTION_DESC = "DESC";

export let NOTIFICATION_SUCCESS_MESSAGE_TIMEOUT = 3000;